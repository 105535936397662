import UserEvent from "../../../core/internal/event/UserEvent"
import WorkspaceFetcher from "../../../core/internal/workspace/WorkspaceFetcher"
import HackleCore from "../../../core/HackleCore"
import { InAppMessage } from "../../../core/internal/model/model"
import PropertyUtil from "../../../core/internal/util/PropertyUtil"
import { InAppMessagePresentationContext } from "../presentation/InAppMessagePresenter"
import { InAppMessageEventMatcher } from "./InAppMessageEventMatcher"

export class InAppMessageDeterminer {
  constructor(
    private readonly workspaceFetcher: WorkspaceFetcher,
    private readonly eventMatcher: InAppMessageEventMatcher,
    private readonly core: HackleCore
  ) {}

  determineOrNull(event: UserEvent): InAppMessagePresentationContext | undefined {
    const workspace = this.workspaceFetcher.get()
    if (!workspace) return

    for (let inAppMessage of workspace.getInAppMessages()) {
      if (this.eventMatcher.matches(workspace, inAppMessage, event)) {
        const context = this.context(inAppMessage, event)
        if (!context) continue

        return context
      }
    }

    return undefined
  }

  private context(inAppMessage: InAppMessage, event: UserEvent): InAppMessagePresentationContext | undefined {
    const decision = this.core.tryInAppMessage(inAppMessage.key, event.user)
    if (decision.inAppMessage && decision.message) {
      return new InAppMessagePresentationContext(
        decision.inAppMessage,
        decision.message,
        event.user,
        PropertyUtil.sanitize({ decision_reason: decision.reason })
      )
    }
    return undefined
  }
}
