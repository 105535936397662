export interface ValueDecorator {
  decorate(value: any): any
}

export class NoopValueDecorator implements ValueDecorator {
  decorate(value: any): any {
    return value
  }
}

export class UrlDecodeValueDecorator implements ValueDecorator {
  decorate(value: any): any {
    return typeof value === "string" ? decodeURI(value) : value
  }
}
