export default class UrlUtil {
  static tryParse(value: any): URL | undefined {
    try {
      return new URL(value)
    } catch (e) {
      return undefined
    }
  }

  static removePathnameTrailingSlash(url: string | URL): string {
    const parsedUrl = url instanceof URL ? url : UrlUtil.tryParse(url)
    if (!parsedUrl) return String(url)

    if (parsedUrl.pathname.endsWith("/")) {
      const SLASH_REG_EXP = /\/+$/
      parsedUrl.pathname = parsedUrl.pathname.replace(SLASH_REG_EXP, "")
    }

    return parsedUrl.href
  }
}
