import { Counter } from "../Counter"
import { Metric, MetricId } from "../Metric"
import { PushMetricRegistry } from "../push/PushMetricRegistry"
import { Timer } from "../Timer"
import { FlushCounter } from "./FlushCounter"
import { isFlushMetric } from "./FlushMetric"
import { FlushTimer } from "./FlushTimer"
import { Scheduler } from "../../scheduler/Scheduler"

export abstract class FlushMetricRegistry extends PushMetricRegistry {
  constructor(scheduler: Scheduler, pushIntervalMillis: number) {
    super(scheduler, pushIntervalMillis)
  }

  createCounter(id: MetricId): Counter {
    return new FlushCounter(id)
  }

  createTimer(id: MetricId): Timer {
    return new FlushTimer(id)
  }

  publish(shutdown: boolean = false) {
    const metrics = this.metrics.reduce<Metric[]>((acc, metric) => {
      if (!isFlushMetric(metric)) return acc
      return acc.concat(metric.flush())
    }, [])

    this.flushMetrics(metrics, shutdown)
  }

  close(): void {
    super.close()
  }

  protected abstract flushMetrics(metrics: Metric[], shutdown: boolean): void
}
