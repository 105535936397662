import UserEvent from "./UserEvent"
import { UserEventEmitter } from "./UserEventEmitter"
import { ScheduledJob, Scheduler } from "../scheduler/Scheduler"
import { EventDispatcher } from "./EventDispatcher"
import Logger from "../logger"

export default interface EventProcessor {
  process(event: UserEvent): void

  flush(shutdown: boolean): void

  close(): void
}

export class DefaultEventProcessor extends UserEventEmitter implements EventProcessor {
  private queue: UserEvent[]
  private flushingJob: ScheduledJob | null

  constructor(
    private readonly eventDispatcher: EventDispatcher,
    private readonly eventDispatchMaxSize: number,
    private readonly flushScheduler: Scheduler,
    private readonly flushIntervalMillis: number,
    private readonly autoTrackPageView: boolean
  ) {
    super()
    this.queue = []
    this.flushingJob = null
  }

  process(event: UserEvent): void {
    if (this.isEnqueueTarget(event)) {
      this.enqueue(event)
    }
    this.emit("event", event)
  }

  private enqueue(event: UserEvent): void {
    if (this.queue.length === 0) {
      this.scheduleFlush()
    }
    this.queue.push(event)

    if (this.queue.length >= this.eventDispatchMaxSize) {
      this.flush()
    }
  }

  private scheduleFlush() {
    this.flushingJob?.cancel()
    this.flushingJob = this.flushScheduler.schedule(this.flushIntervalMillis, () => this.flush())
  }

  private isPageViewEvent(event: UserEvent) {
    return UserEvent.isTrackEvent(event) && event.event.key === "$page_view"
  }

  private isEnqueueTarget(event: UserEvent) {
    if (this.isPageViewEvent(event) && !this.autoTrackPageView) {
      return false
    }

    return true
  }

  flush(shutdown: boolean = false): void {
    this.flushingJob?.cancel()

    if (this.queue.length === 0) {
      return
    }

    this.eventDispatcher.dispatch(this.queue, shutdown).catch((e) => Logger.log.warn(`Failed to dispatch events: ${e}`))
    this.queue = []
  }

  close(): void {
    this.flush(true)
  }
}
