import Logger from "../../logger"
import { MetricRegistry } from "../MetricRegistry"
import { ScheduledJob, Scheduler } from "../../scheduler/Scheduler"

export abstract class PushMetricRegistry extends MetricRegistry {
  private publishingJob: ScheduledJob | null

  constructor(private readonly scheduler: Scheduler, private readonly pushIntervalMillis: number) {
    super()
    this.publishingJob = null
  }

  abstract publish(shutdown: boolean): void

  start() {
    if (this.publishingJob !== null) return
    this.publishingJob = this.scheduler.schedulePeriodically(this.pushIntervalMillis, this.pushIntervalMillis, () =>
      this.publish(false)
    )
    Logger.log.debug(`PushMetricRegistry started. Publish metrics every ${this.pushIntervalMillis}ms.`)
  }

  stop() {
    this.publishingJob?.cancel()
    this.publishingJob = null
    this.publish(true)
    Logger.log.debug(`PushMetricRegistry stopped.`)
  }

  close(): void {
    this.stop()
  }
}
