import { BROWSER_BATCH_SIZE } from "../../../../config"
import Logger from "../../logger"
import { Decision, FeatureFlagDecision, InAppMessageDecision, RemoteConfigDecision } from "../../model/model"
import CollectionUtil from "../../util/CollectionUtil"
import ObjectUtil from "../../util/ObjectUtil"
import { Counter, isCounterMetric } from "../Counter"
import { FlushCounter } from "../flush/FlushCounter"
import { FlushMetricRegistry } from "../flush/FlushMetricRegistry"
import { FlushTimer } from "../flush/FlushTimer"
import { Metric, MetricId } from "../Metric"
import { Metrics } from "../Metrics"
import { isTimerMetric, Timer, TimerSample } from "../Timer"
import { Transport, TransportRequest, TransportResponse } from "../../transport/Transport"
import { Scheduler } from "../../scheduler/Scheduler"

export class MonitoringMetricRegistry extends FlushMetricRegistry {
  constructor(
    private readonly url: string,
    private readonly transport: Transport,
    private readonly shutdownTransport: Transport,
    scheduler: Scheduler,
    pushIntervalMillis: number
  ) {
    super(scheduler, pushIntervalMillis)
    this.start()
  }

  protected flushMetrics(metrics: Metric[], shutdown: boolean): void {
    CollectionUtil.chunked(metrics.filter(this.isDispatchTarget.bind(this)), BROWSER_BATCH_SIZE).forEach(
      (targetMetrics) => {
        this.dispatch(targetMetrics, shutdown)
      }
    )
  }

  // Dispatch only measured metrics
  private isDispatchTarget(metric: Metric): boolean {
    if (isCounterMetric(metric) || isTimerMetric(metric)) return metric.count() > 0

    return false
  }

  private dispatch(metrics: Metric[], shutdown: boolean) {
    const request = this.createRequest(metrics)
    this.execute(request, shutdown).catch((e) => Logger.log.debug(`Failed to flushing metrics: ${e}`))
  }

  private createRequest(metrics: Metric[]): TransportRequest {
    return TransportRequest.builder()
      .url(this.url)
      .method("POST")
      .body(JSON.stringify(this.batch(metrics)))
      .addHeader("Content-Type", "application/json")
      .build()
  }

  private execute(request: TransportRequest, shutdown: boolean): Promise<TransportResponse> {
    if (shutdown) {
      return this.shutdownTransport.send(request)
    } else {
      return this.transport.send(request)
    }
  }

  private batch(metrics: Metric[]) {
    return {
      metrics: metrics.map((metric) => ({
        name: metric.id.name,
        type: metric.id.type,
        tags: metric.id.tags,
        measurements: ObjectUtil.fromMap(
          CollectionUtil.associate(metric.measure(), (measurement) => [measurement.field, measurement.value])
        )
      }))
    }
  }

  createCounter(id: MetricId): Counter {
    return new FlushCounter(id)
  }

  createTimer(id: MetricId): Timer {
    return new FlushTimer(id)
  }

  close() {
    super.close()
  }
}

export class DecisionMetrics {
  static experiment(sample: TimerSample, key: number, decision: Decision) {
    const timer = Metrics.timer("experiment.decision", {
      key: String(key),
      variation: decision.variation,
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static featureFlag(sample: TimerSample, key: number, decision: FeatureFlagDecision) {
    const timer = Metrics.timer("feature.flag.decision", {
      key: String(key),
      on: String(decision.isOn),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static remoteConfig(sample: TimerSample, key: string, decision: RemoteConfigDecision) {
    const timer = Metrics.timer("remote.config.decision", {
      key: String(key),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static inAppMessage(sample: TimerSample, key: number, decision: InAppMessageDecision) {
    const timer = Metrics.timer("iam.decision", {
      key: String(key),
      show: String(decision.isShow()),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }
}

export type ApiOperation = "get.workspace" | "post.events" | "get.cohorts"

export class ApiCallMetrics {
  static record(operation: ApiOperation, sample: TimerSample, response: TransportResponse | null) {
    const timer = Metrics.timer("api.call", {
      operation,
      success: ApiCallMetrics.success(response)
    })
    sample.stop(timer)
  }

  private static success(response: TransportResponse | null): string {
    if (response === null) {
      return "false"
    }
    return String(response.isSuccessful() || response.isNotModified())
  }
}
