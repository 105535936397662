import ConditionMatcher from "./ConditionMatcher"
import Evaluator, { EvaluatorContext, EvaluatorEvaluation, EvaluatorRequest } from "../evalautor/Evaluator"
import { DecisionReason, Experiment, TargetCondition } from "../../model/model"
import ValueOperatorMatcher from "./ValueOperatorMatcher"
import ExperimentEvaluation from "../evalautor/experiment/ExperimentEvaluation"
import ObjectUtil from "../../util/ObjectUtil"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import TypeUtil from "../../util/TypeUtil"

export default class ExperimentConditionMatcher implements ConditionMatcher {
  private readonly abTestMatcher: AbTestConditionMatcher
  private readonly featureFlagMatcher: FeatureFlagConditionMatcher

  constructor(abTestMatcher: AbTestConditionMatcher, featureFlagMatcher: FeatureFlagConditionMatcher) {
    this.abTestMatcher = abTestMatcher
    this.featureFlagMatcher = featureFlagMatcher
  }

  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    switch (condition.key.type) {
      case "AB_TEST":
        return this.abTestMatcher.matches(request, context, condition)
      case "FEATURE_FLAG":
        return this.featureFlagMatcher.matches(request, context, condition)
      case "USER_ID":
      case "USER_PROPERTY":
      case "HACKLE_PROPERTY":
      case "EVENT_PROPERTY":
      case "SEGMENT":
      case "COHORT":
        throw new Error(`Unsupported TargetKeyType [${condition.key.type}]`)
    }
  }
}

abstract class ExperimentMatcher {
  protected readonly evaluator: Evaluator
  protected readonly valueOperatorMatcher: ValueOperatorMatcher

  protected constructor(evaluator: Evaluator, valueOperatorMatcher: ValueOperatorMatcher) {
    this.evaluator = evaluator
    this.valueOperatorMatcher = valueOperatorMatcher
  }

  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    const key = ObjectUtil.requiredNotNullOrUndefined(
      TypeUtil.asNumber(condition.key.name),
      () => `Invalid key [${condition.key.type}, ${condition.key.name}]`
    )

    const experiment = this.experiment(request, key)
    if (ObjectUtil.isNullOrUndefined(experiment)) {
      return false
    }

    const evaluation = context.get(experiment) ?? this.evaluate(request, context, experiment)

    return this.evaluationMatches(evaluation as ExperimentEvaluation, condition)
  }

  private evaluate(request: EvaluatorRequest, context: EvaluatorContext, experiment: Experiment): EvaluatorEvaluation {
    const experimentRequest = ExperimentRequest.by(request, experiment)
    const evaluation = this.evaluator.evaluate(experimentRequest, context)
    const resolvedEvaluation = this.resolve(request, evaluation as ExperimentEvaluation)
    context.addEvaluation(resolvedEvaluation)
    return resolvedEvaluation
  }

  abstract experiment(request: EvaluatorRequest, key: number): Experiment | undefined

  abstract resolve(request: EvaluatorRequest, evaluation: ExperimentEvaluation): ExperimentEvaluation

  abstract evaluationMatches(evaluation: ExperimentEvaluation, condition: TargetCondition): boolean
}

export class AbTestConditionMatcher extends ExperimentMatcher {
  constructor(evaluator: Evaluator, valueOperatorMatcher: ValueOperatorMatcher) {
    super(evaluator, valueOperatorMatcher)
  }

  private static AB_TEST_MATCHED_REASONS = [
    DecisionReason.OVERRIDDEN,
    DecisionReason.TRAFFIC_ALLOCATED,
    DecisionReason.EXPERIMENT_COMPLETED,
    DecisionReason.TRAFFIC_ALLOCATED_BY_TARGETING
  ]

  experiment(request: EvaluatorRequest, key: number): Experiment | undefined {
    return request.workspace.getExperimentOrNull(key)
  }

  resolve(request: EvaluatorRequest, evaluation: ExperimentEvaluation): ExperimentEvaluation {
    if (request instanceof ExperimentRequest && evaluation.reason === DecisionReason.TRAFFIC_ALLOCATED) {
      return evaluation.with(DecisionReason.TRAFFIC_ALLOCATED_BY_TARGETING)
    }
    return evaluation
  }

  evaluationMatches(evaluation: ExperimentEvaluation, condition: TargetCondition): boolean {
    if (!AbTestConditionMatcher.AB_TEST_MATCHED_REASONS.includes(evaluation.reason)) {
      return false
    }
    return this.valueOperatorMatcher.matches(evaluation.variationKey, condition.match)
  }
}

export class FeatureFlagConditionMatcher extends ExperimentMatcher {
  constructor(evaluator: Evaluator, valueOperatorMatcher: ValueOperatorMatcher) {
    super(evaluator, valueOperatorMatcher)
  }

  experiment(request: EvaluatorRequest, key: number): Experiment | undefined {
    return request.workspace.getFeatureFlagOrNull(key)
  }

  resolve(request: EvaluatorRequest, evaluation: ExperimentEvaluation): ExperimentEvaluation {
    return evaluation
  }

  evaluationMatches(evaluation: ExperimentEvaluation, condition: TargetCondition): boolean {
    const on = evaluation.variationKey !== "A"
    return this.valueOperatorMatcher.matches(on, condition.match)
  }
}
